import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';

import LazyStarsCanvas from '../components/Canvas/StarsCanvas/LazyStarsCanvas';
import Seo from '../components/Seo';
import TextGradient from '../components/TextGradient';
import type { GatsbyPageProps } from '../types';
import Link from '../components/Link';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles<Theme>(({ breakpoints, spacing }) => ({
  headline: {
    marginBottom: spacing(4),
  },
}));

type Data = any;

const UkrainePage: React.FC<GatsbyPageProps<Data>> = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      <Seo
        description="Support Ukraine in war againts russia"
        path="/ukraine/"
        title="Support Ukraine"
      />
      <Box mt={16} />
      <Container>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Typography
              className={classes.headline}
              component="h1"
              gutterBottom
              variant="h1"
            >
              <TextGradient>Ukraine</TextGradient> still needs your support.
            </Typography>
          </Grid>

          <Grid item md={6}>
            <Typography className={classes.headline} gutterBottom variant="h4">
              First of all, I want to <TextGradient>thank you</TextGradient> for
              any support you have provided for 🇺🇦.
            </Typography>
            <Typography className={classes.headline} gutterBottom>
              Ukraine has been fighting for its freedom against the russian
              federation since 2014, and the conflict has escalated to full
              scale in 2022.{' '}
              <b>
                <u>russian is committing genocide against Ukraine</u>
              </b>
              , killing children and civilians every day.
            </Typography>
            <Typography className={classes.headline} gutterBottom variant="h6">
              Therefore, we still need you to stand with us for a full victory
              against the aggressor.
            </Typography>

            <Typography className={classes.headline} gutterBottom variant="h6">
              How can I donate?
            </Typography>
          </Grid>
          <Grid container>
            {[
              {
                name: 'OFFICIAL FUNDRAISING PLATFORM OF UKRAINE',
                logoSrc: '/images/ukraine/united24.svg',
                url: 'https://u24.gov.ua/',
              },
              {
                name: 'Come Back Alive',
                logoSrc: '/images/ukraine/savelife.svg',
                url: 'https://savelife.in.ua/en/',
                description:
                  'One of the most effective and trusted foundations in Ukraine. Since 2014, our key goal has been to make the Defense Forces more efficient.',
              },
            ].map((found, i) => (
              <Grid item xs={12} md={6} key={i}>
                <Box
                  p={6}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Box textAlign="center">
                    <a
                      href={found.url}
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                    >
                      <Tooltip title={found?.description ?? ''}>
                        <img
                          src={found.logoSrc}
                          alt=""
                          style={{
                            width: 300,
                            display: 'block',
                            margin: '20px auto',
                          }}
                        />
                      </Tooltip>
                    </a>
                    <Link
                      href={found.url}
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                      underlined
                      bold
                      color="white"
                    >
                      <span>{found.name}</span>
                    </Link>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
      <LazyStarsCanvas />
    </>
  );
};

export default UkrainePage;
